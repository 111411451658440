<template>
  <vs-card>
    <h4 class="mb-2 ml-5 mt-2">Please fill the notification details</h4>
    <form autocomplete="off">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6"></vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label style="padding-left:5px;">Title</label>
            <vs-input
              :danger="errors.first('title') ? true : false"
              :success="!errors.first('title') && notificationData.title !== ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="title"
              name="title"
              v-model="notificationData.title"
              class="w-full mt-6"
            />
            <span class="text-danger text-sm">{{ errors.first("title") }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6"></vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label style="padding-left:5px;">Message</label>
            <vs-textarea
              :class="errors.first('message') ? 'error-input' : ''"
              :success="!errors.first('message') && notificationData.message!=''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="(errors.first('message') ? true : false)"
              v-validate="'required'"
              name="message"
              data-vv-validate-on="blur"
              data-vv-as="message"
              v-model="notificationData.message"
            />
            <span class="text-danger text-sm">
              {{
              errors.first("message")
              }}
            </span>
          </div>
        </vs-col>
        <vs-col vs-w="6"></vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-button
              size="normal"
              :disabled="!validateForm"
              @click="sendPushNotification"
              icon-pack="feather"
              class="mr-2"
            >Send Notification</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </form>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { notificationMixin } from "../../mixins/notificationMixin";
const dict = {
  custom: {
    title: {
      required: "Please enter notification title"
    },
    message: {
      required: "Please enter notification message"
    }
  }
};
Validator.localize("en", dict);

export default {
  name: "SendAdminNotification",
  props: {
    backRoute: {
      type: String,
      default: "SuperAdminPushNotification"
    }
  },
  mixins: [notificationMixin],
  data: () => ({
    notificationData: {
      title: "",
      message: "",
    }
  }),
  methods: {
    async sendPushNotification() {
      let isValid = await this.$validator.validate();
      if (isValid) {
        this.$vs.loading();
        this.sendNotificationByAdmin(this.notificationData)
          .then(res => {
            if (this.$store.state.AppActiveUser.userType === "superAdmin")
              this.$router.push("/super-admin/push-notification");
            else this.$router.push("/admin/push-notifications");
            this.$vs.notify({
              title: "Success",
              text: "Push notification sent successfully",
              color: "success"
            });
            this.$vs.loading.close();
          })
          .catch(err => {
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger"
            });
            this.$vs.loading.close();
          });
      }
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    }
  }
};
</script>

<style scoped>
.vs-con-input-label {
  margin-top: 0 !important;
}
.vs-con-textarea {
  margin-bottom: 5px !important;
}
.error-input {
  border: 1px solid rgba(var(--vs-danger), 1) !important;
}
</style>
